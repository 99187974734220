<!--
 * @Author: Axs <i@axs.email>
 * @Date: 2022-03-15 16:29:51
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2022-03-31 14:38:41
 * @Description: 
 * @FilePath: /src/views/kfc/order/pay-xiaoBuDian.vue
-->
<template>
    <div class="page pay-xiao-bu-dian"
         v-if="!isSafari">
        <div class="title">{{orderInfo.kfcPlaceOrder && orderInfo.kfcPlaceOrder.storeName}} {{orderInfo.eatTypeDesc}}订单</div>
        <div class="address">{{orderInfo.kfcPlaceOrder && orderInfo.kfcPlaceOrder.storeName}}</div>
        <div class="order-amount">{{orderInfo.totalPrice}}</div>
        <template v-if="lockPointStatus">
            <div class="pay-time">支付剩余时间： {{countTimeText}}</div>
            <div class="pay-list">
                <div class="pay-item"
                     :class="[item.class,{
                         'select': index == payTypeIndex
                     }]"
                     v-for="(item,index) in payTypeList"
                     @click="payTypeIndex = index"
                     :key="index">
                    <div class="icon"></div>
                    <div class="name">{{item.name}}</div>
                    <div class="status"></div>
                </div>
            </div>
            <div class="tip">
                <p>{{needIntegral}}积分已被锁定，支付完成后将直接扣除，</p>
                <p>如取消订单，积分将于1小时内返回至小步点账户</p>
            </div>
            <div class="footer iphone-x-adaptation">
                <div class="submit"
                     @click="submit">立即支付</div>
            </div>
        </template>
        <template v-else>
            <div class="error-tip">积分不足{{needIntegral}}，无法享受特价</div>
            <div class="btn-line">
                <div class="btn-item again"
                     @click="reOrder">重新订餐</div>
                <div class="btn-item get-integral"
                     @click="pageJump('xbud://taskList')">去获取积分</div>
            </div>
        </template>
    </div>
    <div class="page pay-xiao-bu-dian"
         v-else>
        <div class="to-app-tip">请在小步点APP内完成支付等相关操作</div>
    </div>
</template>
<script>
    import axios from 'axios';
    import { GET_KFC_ORDER_INFO_OF_ORDER_NO } from 'api';
    import { PAGE_ORDER_INFO } from 'page';
    import number from '@/lib/number';
    import md5 from 'js-md5';
    import xiaoBuDianJsBridge from '@/lib/other/xiaoBuDianJsBridge';
    export default {
        data() {
            return {
                isTest: process.env.VUE_APP_MY_NODE == "PRO" ? false : true,
                orderNo: '',
                orderInfo: {},
                orderInfoUrl: "",
                needIntegral: 0,
                payTypeList: [],
                payTypeIndex: 0,
                countTimer: null,
                countTimeNum: 0,
                countTimeText: '--:--',
                lockPointStatus: true,
                xiaoBuDianToken: '',
                returnUrl: "",
                isLockOk: false,
                isSafari: false,
                pageBack: 0,
            }
        },
        created() {
            this.orderNo = this.$route.query.orderNo || '';
            this.orderType = this.$route.query.orderType || '';
            this.orderInfoUrl = this.$route.query.orderInfoUrl || "";
            this.returnUrl = this.$route.query.returnUrl || "";
            this.pageBack = this.$route.query.pageBack || 0;
            this.isLockOk = false;
            this.isSafari = this.$util.isSafari();
            if (this.isSafari) {
                window.location.href = 'xbud://index';
                return;
            }
            else if (this.pageBack == 1 && !this.isSafari) {
                window.history.go(-2);
            }
            // 获取小步点用户Token
            this.getXiaoBuDianToken();
            this.getOrderInfo();
            this.getPayList();
        },
        methods: {
            // 获取订单详情
            getOrderInfo(type = 0) {
                if (this._isDestroyed) {
                    return;
                }
                this.$util.get(GET_KFC_ORDER_INFO_OF_ORDER_NO, {
                    orderNo: this.orderNo
                }).then(res => {
                    if (res.success) {
                        this.orderInfo = res.data;
                        // 计算所需积分
                        let needIntegral = 0;
                        for (let i = 0; i < res.data.kfcPlaceOrder.items.length; i++) {
                            const item = res.data.kfcPlaceOrder.items[i];
                            let renduce = number.operation(`${item.originPrice}-${item.price}`);
                            let renduceTotal = number.operation(`${renduce}*${item.quantity}`);
                            let tempNeedIntegral = number.operation(`${renduceTotal}*20`);
                            needIntegral = number.operation(`${needIntegral}+${tempNeedIntegral}`);
                        }
                        this.needIntegral = +needIntegral;

                        // 待支付设置倒计时
                        if (this.orderInfo.status == 0) {
                            this.countTimeNum = this.orderInfo.paymentExpireSeconds;
                            this.setCountTimer();
                            setTimeout(() => {
                                this.getOrderInfo(0);
                            }, 2000);
                        }
                        else if (type == 1) {
                            this.jumpOrderInfo();
                        }
                        else if (this.orderInfo.status != 0) {
                            this.jumpOrderInfo();
                        }
                    }
                })
            },
            // 获取小步点Token
            getXiaoBuDianToken() {
                if (process.env.VUE_APP_MY_NODE == 'LOCAL') {
                    this.xiaoBuDianToken = '30667685-dd89-4bc1-b5fc-4aa9e91b0649';
                    this.lockingIntegral();
                }
                xiaoBuDianJsBridge.callHandler(
                    "userInfoBean",
                    ["token"],
                    (res) => {
                        if (typeof res == 'string') {
                            res = JSON.parse(res);
                        }
                        this.xiaoBuDianToken = res.token;
                        this.lockingIntegral();
                    }
                );
            },
            // 锁定积分
            lockingIntegral() {
                this.$util.getUserInfo().then(res => {
                    if (res.success) {
                        let requestUrl = this.isTest ? 'http://pre.campusapi.xbud.run' : 'https://campusapi.xbud.run';
                        let uid = this.$store.state.user.userInfo.platformUniqueId;
                        let orderNo = this.orderNo;
                        let token = this.xiaoBuDianToken;
                        let timestamp = new Date().getTime();
                        let sign = md5(`orderNo=${orderNo}&timestamp=${timestamp}&token=${token}&uid=${uid}`);
                        let url = requestUrl + '/v1/kfc/lock_point';
                        let data = { uid, orderNo, token, timestamp, sign };

                        axios({
                            method: 'post',
                            url: requestUrl + '/v1/kfc/lock_point',
                            headers: {
                                'Content-Type': 'application/json;charset=UTF-8',
                            },
                            data: data,
                        }).then(res => {
                            if (200 == res.status) {
                                // [锁定成功,订单已锁定]
                                if ([10000, -10003].indexOf(res.data.error) > -1) {
                                    this.lockPointStatus = true;
                                }
                                else if ([-10001, -10002, -10004].indexOf(res.data.error) > -1) {
                                    this.lockPointStatus = false;
                                    this.$toast({
                                        message: res.data.message,
                                        duration: 3000,
                                    });
                                }
                                else {
                                    this.lockPointStatus = false;
                                    this.$toast({
                                        message: res.data.message,
                                        duration: 3000,
                                    });
                                }
                                this.isLockOk = true;
                            }
                            else {
                                this.isLockOk = false;
                                this.$toast('网络打了一个瞌睡～')
                            }
                        }).catch(error => {
                            console.error(error)
                        });
                    }
                })
            },
            // 获取支付方式
            getPayList() {
                let res = {
                    data: [{
                        payChannelCode: "YEEPAY",
                        payChannelName: "易宝",
                        payWayCode: "ALIPAY_WAP",
                        payWayName: "支付宝H5"
                    }, {
                        payChannelCode: "WX",
                        payChannelName: "微信",
                        payWayCode: "WX_H5",
                        payWayName: "微信H5"
                    }]
                }
                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    switch (item.payWayCode) {
                        // TODO
                        case 'WX_H5':
                            item.name = '微信支付';
                            item.class = 'wechat';
                            break;
                        case 'WX_JSAPI':
                            item.name = '微信支付';
                            item.class = 'wechat';
                            break;
                        case 'ALIPAY_WAP':
                            if (item.payChannelCode == "YEEPAY") {
                                item.name = '支付宝支付';
                            }
                            else {
                                item.name = '支付宝';
                            }
                            item.class = 'alipay';
                            break;
                        default:
                            break;
                    }
                }
                this.payTypeList = res.data;
                // this.$util.post('/api/cashier/listPayWay', {
                //     portalCode: 'H5'
                // }).then(res => {
                //     if (res.success) {

                //     }
                // })
            },
            // 设置倒计时计时器
            setCountTimer() {
                if (this.countTimer) {
                    clearInterval(this.countTimer)
                    this.countTimer = null;
                }
                this.setCountTimeText();
                this.countTimer = setInterval(() => {
                    if (this.countTimeNum < 1 || this._isDestroyed) {
                        clearInterval(this.countTimer)
                        this.countTimer = null;
                        this.jumpOrderInfo();
                    } else {
                        this.countTimeNum--;
                        this.setCountTimeText();
                    }
                }, 1000);
            },
            // 设置倒计时时间
            setCountTimeText() {
                let minute = ~~(this.countTimeNum / 60);
                let second = this.countTimeNum % 60;
                this.countTimeText = `${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
            },
            // 跳转订单详情
            jumpOrderInfo() {
                if (this.orderInfoUrl) {
                    window.location.replace(this.orderInfoUrl)
                }
            },
            // 提交支付
            submit() {
                if (!this.isLockOk) {
                    return this.$toast(`积分锁定中，请稍后重试`);
                }
                let payItem = this.payTypeList[this.payTypeIndex];
                // 易宝支付宝
                if (payItem.payChannelCode == 'YEEPAY' && payItem.payWayCode == 'ALIPAY_WAP') {
                    this.yeeAlipay(payItem);
                }
                // 易宝微信
                else if (payItem.payChannelCode == 'YEEPAY' && payItem.payWayCode == 'WX_JSAPI') {
                    this.yeeWechatPay(payItem);
                }
                // 官方支付宝
                else if (payItem.payChannelCode == 'ALIPAY' && payItem.payWayCode == 'ALIPAY_WAP') {
                    this.qianZhuPay(payItem, 1);
                }
                // 官方微信H5
                else if (payItem.payChannelCode == 'WX' && payItem.payWayCode == 'WX_H5') {
                    this.qianZhuPay(payItem, 2);
                }
            },
            // 易宝支付宝
            yeeAlipay(payItem) {
                this.$util.post(`/api/cashier/createTrade`, {
                    orderNo: this.orderNo,
                    orderType: this.orderType,
                    payWayCode: payItem.payWayCode,
                    payChannelCode: payItem.payChannelCode,
                    returnUrl: `${window.location.origin}${process.env.VUE_APP_BASE_URL}/pay-center-page/?schema=xbud%3A%2F%2F`
                }).then(res => {
                    if (res.success) {
                        this.$iframe.close()
                        this.$iframe.open({ url: res.data.payData.prePayTn })
                    }
                })
            },
            // 易宝微信
            yeeWechatPay(payItem) {
                this.$util.post(`https://${this.isTest ? 'nf-test' : 'nf'}.qianzhu8.com/v1/wxMa/generateScheme`, {
                    appId: "10002",
                    path: "pages/loading/loading",
                    query: `pageType=1&orderNo=${this.orderNo}&orderType=${this.orderType}&token=${this.$store.state.user.token}${this.isTest ? '&isTest=true' : ''}`,
                    envVersion: process.env.VUE_APP_WX_MINI_VERSION
                }).then(res => {
                    if (res.success) {
                        window.location.href = res.data;
                    }
                })
            },
            /**
             * 官方支付
             * type: 1 支付宝
             *       2 微信H5
             *       3 微信jssdk
             */
            qianZhuPay(payItem, type) {
                this.$util.post(`/api/cashier/createTrade`, {
                    orderNo: this.orderNo,
                    orderType: this.orderType,
                    payWayCode: payItem.payWayCode,
                    payChannelCode: payItem.payChannelCode,
                    returnUrl: window.location.href + `&pageBack=1&token=${this.$store.state.user.token}`
                }).then(res => {
                    if (res.success) {
                        if (type == 1) {
                            window.location.href = res.data.payData;
                        }
                        else if (type == 2) {
                            window.location.href = res.data.payData;
                        }
                    }
                })
            },
            // 点击支付之后弹窗确认是否已经完成支付
            payOverConfirm() {
                setTimeout(() => {
                    this.$confirm.open({
                        title: "",
                        message: "想问问您的支付情况呢～",
                        confirmText: "我已支付",
                        cancelText: "暂未支付",
                        success: () => {
                            this.getOrderInfo(1);
                        },
                        error: () => {
                            this.getOrderInfo(1);
                        }
                    })
                }, 2000);
            },
            // 重新订购
            reOrder() {
                window.location.replace(process.env.VUE_APP_BASE_URL || '/');
            },
            // 页面跳转
            pageJump(url) {
                window.location.replace(url)
            },
        }
    }
</script>

<style lang="less" scoped>
    @import url('~@/lib/base.less');
    @alipayIconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABaFBMVEUAAAAAAP8Af/8A//8Aqv8Af/8Av/8Amf8Atv8Av/8Aqv8As/8Auf8Aqv8Ar/8AtP8AuP8Arv8Atf8Ar/cAtP8HsfgGtP8GtP8Gtf8Ftf8Ftf8FsvoFt/8Ftf8Ftv8EsfsEtv8Et/8EtP8Es/8EtP8EsvsEsf8Etf8EsvsGs/8Gtf8GtfwFsvwFtfwFtPwFtP8FsvwFtfwFtf0EtP0Gtf0GtP8Gtf8Gs/0Gtf8GtP0GtP0Gs/0Gs/0FtP0Fs/0Fs/0FtP0Fs/0FtP0Fs/0FtP0FtP0FtP0GtP0Gs/wGtf4FtPwFs/wFtf4FtP4FtP4Ftf4FtP4FtPwFtP4FtP4FtPwFtP4GtP4FtP4GtP4FtP4Gs/0Gtf4GtP4GtP4Gs/0GtP0Gs/0Gtf0GtP0Gs/0GtP0Fs/0FtP0FtP4FtP0FtP0FtP4Fs/0FtP0FtP4FtP0FtP0FtP0Ftf0FtP0GtP4Gs/0GtP4GtP0GtP0KG6AAAAAAd3RSTlMAAQICAwQEBQcICQoLDxAREhYfICInKSwtMDQ1NTc4Ozs8PUBBQkVFRlRZWl1gYmNkZHFzeHt/gICBhIaHj5CRlZaWl5mdn6ers7u9vb/BwcTFxcbIzNLT09TY2Nrc3t/i4uPl5enq7O3u7u/v8/f4+vr7/P39/oWSgHoAAAFVSURBVDjLY2CgK5Cw8EnJz0/xsZDAKq3oVw4HfooY0pxOZeVIoMyJE1WeL6AcDQTwoejHkAeqQDbDCSaaZmebB2M7IbkPbr8vA0Mm3B0Il4Ldn+8BBOYMDG4gOgnsF7j/wRpyTYBAm4HB2BQIIsBCsPCwQLgM2YrycguoAh8IN1RWVlaMgUFSSkoqGiLiA1WQAuEWZUOAPgN/EUQkBaogH+rq9CyQb8qEGPSgVuQjK0g15GdgENCwCQM6IwRNAciKDBXX8IRQR2VGBi4GJZgjU5AcaR0MEYuUBgoouOSAOd5I3jSIhygI4mFQZQZGjpZXSXm5GVJAORuBHFjsxM3qVB6lwwIUFbZMFEcK6kI1dTdPK1EGMX+QOTG6bEBhJpTIKrCXExTRdC+Aui9Wj50BW3QjgTgZAgkmkINAkuPFm2hLHTjwJfsyX3ncGScv2ctMgr4ZFgBEmciWt6BwyQAAAABJRU5ErkJggg==';
    @wechatIconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABblBMVEUAAAAA/wAAfwAA/wB//39VqlVV/1VV1FVJ20lAv0BVxlVV41VNzE1G0UZd0V1Qz1BR11FVzlVV1FVSzFJS1lJYzlhV0FVSyFJV0VVVzVVa1lpXzVdYzlNXz1da0FVY0VhX0VdYz1hY0VRWzlZV0VVWzlZVzVVZzVVXzldWz1ZXzVdY0VVWz1ZYzlVYzlhWzlZXz1dX0VdWz1ZYz1ZXzVdY0FZX0VdYz1ZXzlVZ0FZXz1dY0FZYz1ZYz1ZYz1hYz1ZZ0VdX0FdYz1ZXz1dXzldX0FdXz1dYzlZY0FdYz1ZYz1ZYz1dXzldYz1ZYz1ZX0FZY0FdYz1ZYz1dY0FZX0FdXz1dYz1dYz1ZXz1dYz1dY0FdYz1dYz1dYz1dXzldYz1dXz1ZXzldX0FdXzldYz1dYz1dYz1dXzlZXz1dY0FdY0FdY0FdYz1ZYz1ZXz1ZX0FZYz1dYz1dYz1dY0FdYz1dYz1dY0FdXz1ZYz1dYz1epOfR5AAAAeXRSTlMAAQICAgMDBgcICQkKCwsQExUYGRkaGxwhJCUpNDU2Nzg6PT5CREhISUpMTlBUVFlbZGVlZ2hqa3JzdXx/gICGioyOj5KYmZ2foKOlra6xsrO0tre4ubm6xMXHyMrLzc7P0NLW2tvg4eHi5+jp7PLy8/T29/r9/f7++HMvqAAAARFJREFUGBnNwYVCwlAABdAL2MwWC8RO7O7CVkwUBTuwUDHmvH8ve6M29gGcgyxTP7Z2EgoGVsedMJE/csOk24kCGLQ/UOepBzrzMg2URStSvDThRdIQM4WnSpFQ+U6j0/48uAIOaJao97PdADg2ZS5DsL4wJuyn5m2hGiiaiZKMWKByUnVvG46SvBuVAAw8UnBC1U2hD3V7ux0WAO4zxnVC5aFwDk3N1i8TPFA1UtOGGGn2kyluqOzfFPyAZfCZab7sEHwUlK6WEHV2oGlWaEppQtwGTa0joeSaJi6KkVR+yQzBMqSxH1JPXimEziTTyQcuGOxT8c0dR/5I5fVouhZGuR9XrYiRqiokmMnptSF7/QP9jcP0tcPinwAAAABJRU5ErkJggg==';
    @payTypeNoSelectUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAQlBMVEUAAAC/v7+2trbJycnX19fMzMzNzc3T09PU0NDR0dHU0dHT0NDS0NDR0dHT0dHS0dHS0dHR0NDR0dHS0dHS0dHR0NB3iFYeAAAAFnRSTlMABAcTExQkKTxCZGiIqKy819jY3N3wimpRmAAAAJNJREFUKM+FUlsSgyAMVEQj8gjyuP9Vq6BtIY3NB8Nkyexml2H4WyMoBSNprzYHxJDt2rRnF7U8L1JHN3/6SzLivguTlvf7tH2Pb+mecablM+7ijaIFRKwKrO4lalv056kHZD73AU+X3eE4FFIA1RMAOwUCFHL5m5yVyy/IWsKbyNpegyrGTG1QNVqP6PtoHz4DqRfTqAXOctZCVAAAAABJRU5ErkJggg==';
    @payTypeSelectUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAYFBMVEUAAAD//wD/4wD/5gD/6wD/4wD/4wD/6wD/5gD/5gD/5QD/5gD/5AD/6AD/5QD/5wD/5gD/5gD/5wD/5gD/5wD/5gD/5gD/5gD/5gD/5gH/5wH/5gH/5gH/5gH/5gH/5gGrkt7LAAAAH3RSTlMABBIUGiQlJygpPD1CQoCAhKGorKyuu83e5ubo6fLzAKO+QwAAAJtJREFUKM+FktsWgiAQRQ+ZFaZdyEor2v//lz1oCLqo/bgXMDNnkP5ibNNYs9CV8wDeVYkuWgJtMfmyI6Irw/nEQ/e90zKjHeuyYOjAJa5/AU6SjI/9fbUHvJFkU2+uAFZSA8DzPfoLAI2kGoD17hF56vDUSdt+8tip+FGbyXsTtXtQ8EO7YcDzbTZgNpJ8iNnY84vKr/bHZ1jwARSNJVuVWOaBAAAAAElFTkSuQmCC';
    .pay-xiao-bu-dian {
        padding-top: .pxToRem(92) [];
        background: #ffffff;
        box-sizing: border-box;
        .title {
            line-height: .pxToRem(50) [];
            font-size: .pxToRem(36) [];
            font-weight: 500;
            color: #212428;
            text-align: center;
            padding: 0 .pxToRem(48) [];
            box-sizing: border-box;
        }
        .address {
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(28) [];
            font-weight: 400;
            color: #212428;
            margin-top: .pxToRem(22) [];
            text-align: center;
            padding: 0 .pxToRem(48) [];
            box-sizing: border-box;
        }
        .order-amount {
            position: relative;
            text-align: center;
            font-size: .pxToRem(60) [];
            font-weight: bold;
            color: #212428;
            height: .pxToRem(72) [];
            line-height: .pxToRem(72) [];
            margin: 0 auto;
            margin-top: .pxToRem(46) [];
            &::before {
                content: '¥';
                position: absolute;
                bottom: 0;
                transform: translateX(-100%);
                padding-bottom: .pxToRem(8) [];
                padding-right: .pxToRem(4) [];
                font-size: .pxToRem(28) [];
                font-weight: 600;
                color: #212428;
                line-height: .pxToRem(40) [];
            }
        }

        .pay-time {
            text-align: center;
            font-size: .pxToRem(24) [];
            font-weight: 400;
            color: #666666;
            line-height: .pxToRem(34) [];
            margin-top: .pxToRem(20) [];
        }
        .pay-list {
            margin-top: .pxToRem(72) [];
            .pay-item {
                display: flex;
                width: 100%;
                height: .pxToRem(98) [];
                box-sizing: border-box;
                padding-left: .pxToRem(48) [];
                padding-right: .pxToRem(52) [];
                align-items: center;
                .icon {
                    .sq(64);
                }
                .name {
                    flex: 1;
                    font-size: .pxToRem(30) [];
                    font-weight: 400;
                    color: #333333;
                    line-height: .pxToRem(42) [];
                    margin: 0 .pxToRem(24) [];
                }
                .status {
                    .sq(48);
                    background: url(@payTypeNoSelectUrl);
                    background-size: contain;
                }
                &.alipay {
                    .icon {
                        background: url(@alipayIconUrl);
                        background-size: contain;
                    }
                }
                &.wechat {
                    .icon {
                        background: url(@wechatIconUrl);
                        background-size: contain;
                    }
                }
                &.select {
                    .status {
                        background: url(@payTypeSelectUrl);
                        background-size: contain;
                    }
                }
            }
        }
        .tip {
            margin-top: .pxToRem(46) [];
            font-size: .pxToRem(24) [];
            font-weight: 400;
            color: #212428;
            line-height: .pxToRem(34) [];
            padding: 0 .pxToRem(52) [];
            box-sizing: border-box;
        }

        .error-tip {
            font-size: .pxToRem(28) [];
            font-weight: 600;
            color: #f76f6d;
            line-height: .pxToRem(40) [];
            text-align: center;
            padding: 0 .pxToRem(48) [];
            box-sizing: border-box;
            margin-top: .pxToRem(48) [];
        }
        .btn-line {
            display: flex;
            margin-top: .pxToRem(92) [];
            padding: 0 .pxToRem(46) [];
            box-sizing: border-box;
            .btn-item {
                flex: 1;
                margin-right: .pxToRem(18) [];
                width: 100%;
                height: .pxToRem(88) [];
                line-height: .pxToRem(88) [];
                text-align: center;
                border-radius: .pxToRem(44) [];
                font-size: .pxToRem(32) [];
                font-weight: 600;
                &.again {
                    background: #212428;
                    color: #ffe601;
                }
                &.get-integral {
                    background: #ffe601;
                    color: #212428;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .footer {
            position: fixed;
            bottom: .pxToRem(20) [];
            left: .pxToRem(32) [];
            right: .pxToRem(32) [];
            .submit {
                width: 100%;
                line-height: .pxToRem(88) [];
                border-radius: .pxToRem(44) [];
                font-size: .pxToRem(32) [];
                font-weight: 600;
                color: #212428;
                text-align: center;
                background: #ffe601;
            }
        }

        .to-app-tip {
            .base-center;
            width: 100%;
            text-align: center;
            font-size: .pxToRem(32) [];
            color: #666666;
        }
    }
</style>


